@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Inria Sans";
    src: url(/fonts/InriaSans-Regular.woff2) format("woff2");
    font-weight: 400;
  }
  @font-face {
    font-family: "Inria Sans";
    src: url(/fonts/InriaSans-Light.woff2) format("woff2");
    font-weight: 300;
  }
  @font-face {
    font-family: "Inria Sans";
    src: url(/fonts/InriaSans-Bold.woff2) format("woff2");
    font-weight: 700;
  }
  @font-face {
    font-family: "Inria Serif";
    src: url(/fonts/InriaSerif-Light.woff2) format("woff2");
    font-weight: 400;
  }
  @font-face {
    font-family: "IBM Plex Mono";
    src: url(/fonts/IBMPlexMono-Regular.woff2) format("woff2");
    font-weight: 400;
  }
  @font-face {
    font-family: "IBM Plex Mono";
    src: url(/fonts/IBMPlexMono-Italic.woff2) format("woff2");
    font-weight: 400;
    font-style: italic;
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.font-sans {
  content: "";
  margin-bottom: -0.1188em;
  display: table;
}

.font-sans {
  content: "";
  margin-top: -0.3488em;
  display: table;
}

/* 
Using Capsize to Fix Text Alignment 
https://seek-oss.github.io/capsize/
*/
/* .font-serif::before {
  content: "";
  margin-bottom: -0.14em;
  display: table;
}

.font-serif::after {
  content: "";
  margin-top: -0.37em;
  display: table;
} */

/* Nice Scrollbar */
/* rewrite an attractive, minimalist scrollbar that works across browsers */
.simple-v-scrollbar::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.simple-v-scrollbar::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
}

.simple-v-scrollbar::-webkit-scrollbar-thumb {
  @apply bg-neutral-200;
  border-radius: 9999px;
}

.simple-v-scrollbar::-webkit-scrollbar-thumb:hover {
  @apply bg-neutral-400;
}

.simple-v-scrollbar:hover::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.simple-v-scrollbar:hover::-webkit-scrollbar-thumb {
  @apply bg-neutral-400;
}

.simple-v-scrollbar:hover::-webkit-scrollbar-thumb:hover {
  @apply bg-neutral-400;
}

.simple-h-scrollbar::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.simple-h-scrollbar::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
}

.simple-h-scrollbar::-webkit-scrollbar-thumb {
  @apply bg-neutral-200;
  border-radius: 9999px;
}

.simple-h-scrollbar::-webkit-scrollbar-thumb:hover {
  @apply bg-neutral-400;
}

.simple-h-scrollbar:hover::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.simple-h-scrollbar:hover::-webkit-scrollbar-thumb {
  @apply bg-neutral-400;
}

.simple-h-scrollbar:hover::-webkit-scrollbar-thumb:hover {
  @apply bg-neutral-400;
}

.grid-table {
  grid-template-columns: repeat(var(--num-cols), fit-content(100%));
}

/**
Hacky autocomplete placement fix
*/
.cm-tooltip-autocomplete {
  position: static !important;
}

/* Styling the codemirror autocomplete */
.ͼ1l .cm-tooltip.cm-tooltip-autocomplete > ul {
  @apply font-mono text-xs text-neutral-700;
}

.ͼ1m .cm-tooltip {
  @apply rounded-lg bg-neutral-300 border-none border-t border-neutral-500;
}

.ͼ1l .cm-tooltip.cm-tooltip-autocomplete > ul > li {
  @apply p-2;
}

.ͼ1m .cm-tooltip-autocomplete ul li[aria-selected] {
  @apply bg-neutral-600 text-background;
}

body {
  overflow-y: scroll;
}

/* Hide Delete Collection Button unless hovering header */
/* .collection-header button {
  visibility: hidden;
}

.collection-header:hover button {
  visibility: visible;
} */

/* Markdown Editor; uses prose */
.markdown-editor {
  @apply text-base md:text-lg text-neutral-700 resize-none focus:outline-none overflow-hidden max-w-[100%];
}

.markdown-editor h1,
.markdown-editor h2,
.markdown-editor h3,
.markdown-editor h4,
.markdown-editor h5,
.markdown-editor h6 {
  @apply font-bold text-neutral-900;
}

.markdown-editor h1 {
  @apply text-4xl md:text-5xl;
}

.markdown-editor h2 {
  @apply text-3xl md:text-4xl;
}

.markdown-editor h3 {
  @apply text-2xl md:text-3xl;
}

.markdown-editor h4 {
  @apply text-xl md:text-2xl;
}

.markdown-editor h5 {
  @apply text-lg md:text-xl;
}

.markdown-editor h6 {
  @apply text-base md:text-lg;
}

.markdown-editor ul {
  @apply list-disc list-outside pl-4;
}

.markdown-editor ol {
  @apply list-decimal list-outside pl-4;
}

.remirror-list-item-with-custom-mark div {
  display: inline-block;
}

.markdown-editor blockquote {
  @apply border-l-4 border-neutral-300 pl-4 text-neutral-600;
}

.markdown-editor pre[class*="language-"] {
  @apply bg-neutral-100 border border-neutral-300 rounded-xl p-4 font-mono text-sm md:text-base;
  border: none;
  overflow: hidden;
}

.markdown-editor p code {
  @apply bg-neutral-100 border border-neutral-300 rounded-xl p-1 font-mono text-sm md:text-base;
}

/* basic spacing between elements */
.markdown-editor > * + * {
  @apply mt-4;
}

.markdown-editor a {
  @apply text-orange-500 underline;
}

.remirror-editor-wrapper {
  overflow: auto;
  max-height: 75vh;
}

.narrative-editor .remirror-editor-wrapper {
  max-height: 25vh;
  font-family: @apply font-mono text-sm;
}

/* switch */
.switch-wrapper:hover .switch-thumb {
  @apply bg-neutral-500;
}

.grid-table .editable-column-header-settings,
.grid-table .table-cell-content {
  width: 200px;
}
